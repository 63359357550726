<template>
    <div class="room-select" @click.stop="() => {}">
        <div @click="isShowPanel = true" >
            <CSSelect
                height="40px"
                i-width="36px"
                :style="{
                    width: '235px',
                    color: checkedRoomNames.length > 0 ? '#000' : '#999',
                }"
            >
                <div class="checked-rooms">
                    {{
                        checkedRoomNames.length > 0
                            ? checkedRoomNames.toString()
                            : "请选择"
                    }}
                </div>
            </CSSelect>
        </div>
        <div class="room-select-panel" v-show="isShowPanel">
            <!-- <div class="room-select-panel-header">
                <span class="active">空置</span>
                <span>在租</span>
            </div> -->
            <div class="room-select-panel-content">
                <div class="floor-info">
                    <div
                        v-for="building in roomList"
                        :key="building.buildingId"
                    >
                        <div class="building-checkbox-panel">
                            <div
                                @click="
                                    changeUnfold(
                                        'unflodBuilding',
                                        building.buildingId
                                    )
                                "
                            >
                                <div
                                    :class="[
                                        'glyphicon',
                                        'glyphicon-triangle-right',
                                        {
                                            rotate: unflodBuilding.includes(
                                                building.buildingId
                                            ),
                                        },
                                    ]"
                                ></div>
<!--                                <input-->
<!--                                    type="checkbox"-->
<!--                                    v-model="checkedBuildings"-->
<!--                                    disabled-->
<!--                                    :value="building.buildingId"-->
<!--                                />-->
                                <img v-if="checkedBuildings.includes(building.buildingId)"
                                     src="../../public/common/static/img/checkbox/checked.png" alt=""
                                />
                                <img v-else
                                     src="../../public/common/static/img/checkbox/checkedWhite.png" alt=""
                                />
                                {{ building.buildingName }}
                            </div>
                            <div
                                class="floor-checkbox-panel"
                                v-for="floor in building.floors"
                                :key="floor.floor"
                            >
                                <template
                                    v-if="
                                        unflodBuilding.includes(
                                            building.buildingId
                                        )
                                    "
                                >
                                    <div
                                        @click="
                                            changeUnfold(
                                                'unfoldFloor',
                                                floor.floor
                                            )
                                        "
                                    >
                                        <div
                                            :class="[
                                                'glyphicon',
                                                'glyphicon-triangle-right',
                                                {
                                                    rotate: unfoldFloor.includes(
                                                        floor.floor
                                                    ),
                                                },
                                            ]"
                                        ></div>
                                        <img v-if="checkedFloors.includes(building.buildingId + '_' + floor.floor)"
                                             src="../../public/common/static/img/checkbox/checked.png" alt=""
                                        />
                                        <img v-else
                                             src="../../public/common/static/img/checkbox/checkedWhite.png" alt=""
                                        />
                                        {{ floor.floor }}
                                    </div>
                                    <div
                                        class="room-checkbox-panel"
                                        v-if="unfoldFloor.includes(floor.floor)"
                                    >
                                        <template v-for="room in floor.rooms">
                                            <div
                                                class="room-name"
                                                :key="room.roomId"
                                            >
                                                <img v-if="room.rented || room.isTenant" style="cursor: not-allowed;" src="../../public/common/static/img/checkbox/disabledChecked.png" alt="">
                                                <img v-else-if="checkedRoomIds.includes(room.roomId)"
                                                     src="../../public/common/static/img/checkbox/checked.png" alt=""
                                                     @click="changecheckedRoomIds(room,floor,building)"
                                                />
                                                <img v-else
                                                     src="../../public/common/static/img/checkbox/checkedWhite.png" alt=""
                                                     @click="changecheckedRoomIds(room,floor,building)"
                                                />
                                                {{ room.roomNo }}
                                            </div>
                                        </template>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { queryRoomRentUrl,queryRoomRentUrld } from "@/requestUrl";
import CSSelect from "@/components/common/CSSelect";
export default {
    name: "RoomSelect",
    props: {
        selectedRooms: Array,
        isTenant:{
            type:Boolean,
            default:false
        }
    },
    components: {
        CSSelect,
    },
    data() {
        return {
            isShowPanel: false,
            unfoldFloor: [],
            unflodBuilding: [],
            roomList: [],
            checkedRoomIds: [],
            checkedFloors: [],
            checkedBuildings: [],
            checkedRoomNames: [],
            checkedRooms: [],
        };
    },
    watch: {
        async selectedRooms(selectedRooms) {
            const roomList =
                this.roomList.length > 0
                    ? this.roomList
                    : await this.queryRoom();
            roomList.forEach((building) => {
                building.floors.forEach((floor) => {
                    floor.rooms.forEach((room) => {
                        if (selectedRooms.includes(room.roomId)) {
                            this.changecheckedRoomIds(room, floor, building);
                        }
                    });
                });
            });
        },
    },
    methods: {
        // 查询房间列表
        async queryRoom() {
            let url = queryRoomRentUrl;
            let params={
                regionId: this.$vc.getCurrentRegion().communityId,
                state:1
            }
            if(this.isTenant){
                url = queryRoomRentUrld;
                params={
                    regionCode: this.$vc.getCurrentRegion().communityId,
                    state:1
                }
            }
            return this.$fly
                .post(url, params)
                .then((res) => {
                    if(this.isTenant){
                        this.roomList = res.data;
                        return res.data;
                    }
                    this.roomList = res.data.datas;
                    return res.data.datas;
                });
        },
        /**
         * 修改展开/关闭
         * @param {String} property 操作的属性名称
         * @param {Number} id
         *  */
        changeUnfold(property, id) {
            if (this[property].includes(id)) {
                this[property].splice(this[property].indexOf(id), 1);
            } else {
                this[property].push(id);
            }
        },
        /**
         * 更改房间选中状态
         * @param {Object} room 房间信息
         * @prama {Object} floor 楼层信息
         * @param {Object} building 楼宇信息
         *  */
        changecheckedRoomIds(room, floor, building) {
            const id = room.roomId,
                fId = floor.floor,
                bId = building.buildingId;
            if (this.checkedRoomIds.includes(id)) {
                const deleteIndex = this.checkedRoomIds.indexOf(id);
                this.checkedFloors.splice(deleteIndex, 1);
                this.checkedBuildings.splice(deleteIndex, 1);
                this.checkedRoomNames.splice(deleteIndex, 1);
                this.checkedRooms.splice(deleteIndex, 1);
                this.checkedRoomIds.splice(deleteIndex, 1);
            } else {
                this.checkedFloors.push(bId + "_" + fId);
                this.checkedBuildings.push(bId);
                this.checkedRooms.push({
                    buildingName: building.buildingName,
                    buildingId:building.buildingId,
                    ...room,
                });
                this.checkedRoomNames.push(
                    `${building.buildingName}-${room.roomNo}`
                );
                this.checkedRoomIds.push(id);
            }
            this.$emit("change-rooms", {
                roomIds: this.checkedRoomIds,
                rooms: this.checkedRooms,

            });
        },
        setBuildingName (building) {
            this.checkedRoomNames.push(`${building.buildingName ? building.buildingName + '-' : ''}${building.roomCode || ''}`);
        }
    },
    mounted() {
        let contractList = document.getElementsByClassName('contract-modal');
        if(contractList.length > 0){
            for(let  i = 0;i<contractList.length;i++){
                contractList[i].addEventListener("click", () => {
                    this.isShowPanel = false;
                })
            }
        }
        // document
        //     .querySelector(".contract-modal")
        //     .addEventListener("click", () => {
        //         this.isShowPanel = false;
        //     });
    },
    created() {
        this.queryRoom();
    },
};
</script>
<style>
.room-select {
    position: relative;
}

.room-select .custom-input {
    border: 1px solid #979797 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.checked-rooms {
    max-width: 220px;
    padding: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.room-select-panel {
    position: absolute;
    top: 45px;
    left: 0;
    background: #fff;
    width: 255px;
    height: 280px;
    z-index: 2;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.room-select-panel-header {
    text-align: center;
    margin-bottom: 25px;
}

.room-select-panel-header span {
    font-size: 24px;
    display: inline-block;
    position: relative;
    width: 48px;
    height: 33px;
    line-height: 33px;
}

.glyphicon.glyphicon-triangle-right {
    font-size: 10px;
    margin-right: 8px;
    vertical-align: middle;
}

.glyphicon.glyphicon-triangle-right.rotate {
    -webkit-animation: arrowRotate 0.1s linear 1;
    animation: arrowRotate 0.1s linear 1;
    transform: rotate(90deg);
}

@-webkit-keyframes arrowRotate {
    0% {
        -webkit-transform: rotate(0deg);
    }
    50% {
        -webkit-transform: rotate(45deg);
    }
    100% {
        -webkit-transform: rotate(90deg);
    }
}
@keyframes arrowRotate {
    0% {
        -webkit-transform: rotate(0deg);
    }
    50% {
        -webkit-transform: rotate(45deg);
    }
    100% {
        -webkit-transform: rotate(90deg);
    }
}

.room-select-panel-header span:not(:last-of-type) {
    margin-right: 99px;
}

.room-select-panel-header span.active::after {
    position: absolute;
    width: 60px;
    height: 4px;
    content: " ";
    background: #1ab394;
    border-radius: 2px;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
}

.room-select-panel-content {
    padding: 14px;
    height: 100%;
    overflow: auto;
    color: #000;
}

.room-select-panel input[type="checkbox"] {
    vertical-align: middle;
}

.floor-checkbox-panel input[type="checkbox"] {
    margin-right: 10px;
}

.floor-checkbox-panel {
    padding-left: 20px;
}

.room-checkbox-panel {
    padding-left: 40px;
    margin-bottom: 0;
}
.custom-input {
    width: 250px !important;
    padding: 0px 10px 0 10px;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;
    max-width: 234px;
}
</style>
